<template>
  <moe-page title="助力拉新">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">助力拉新</div>
          </div>
        </div>
      </template>

      <moe-inquire @search="assistSearch">
        <el-form-item label="活动状态">
          <moe-select type="assistStateList" v-model="assistParams.listState" placeholder="选择活动状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="assistParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动编号">
          <el-input :value="assistParams.id" @input="(value) => assistParams.id = $moe_formatter.formatterId(value)" placeholder="输入活动编号" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([assistParams.startTime, assistParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
        <el-form-item label="助力对象">
          <moe-select type="assistTargetList" v-model="assistParams.target" placeholder="选择助力对象"></moe-select>
        </el-form-item>
      </moe-inquire>

      <moe-table ref="assistTableRef" url="/shop/assist/list" :params="assistParams" :numberShow="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/assist/add')">新增活动</el-button>

        <el-table-column label="排序" prop="sort" width="100" />

        <el-table-column label="活动信息" min-width="150">
          <template slot-scope="{ row }">
            <div>{{ row.name }}</div>
            <div>活动编号:{{ row.id }}</div>
          </template>
        </el-table-column>

        <el-table-column label="优惠券" min-width="150">
          <template slot-scope="{ row }">
            {{ `满 ${row.condition} 元 减 ${row.discount} 元` }}
          </template>
        </el-table-column>

        <el-table-column label="助力人数" prop="num" min-width="150" />

        <el-table-column label="助力对象" min-width="150">
          <template slot-scope="{ row }">
            {{ $moe_format.getAssistTarget(row.target) }}
          </template>
        </el-table-column>

        <el-table-column label="活动时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
            <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
          </div>
        </el-table-column>

        <el-table-column label="活动状态" min-width="150">
          <div :class="$moe_format.getAssistStateColor(row.listState)" slot-scope="{ row }">
            {{ $moe_format.getAssistState(row.listState) }}
          </div>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="450">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/assist/detail`, { id: row.id })">查看活动</el-button>
            <el-button v-if="['NOT_STARTED', 'IN_PROGRESS'].includes(row.listState)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/assist/add`, { id: row.id })">编辑活动</el-button>
            <el-button v-if="['NOT_STARTED'].includes(row.listState)" type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
            <el-button v-if="['IN_PROGRESS', 'FINISHED'].includes(row.listState)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">关闭</el-button>
            <el-button v-if="!['NOT_STARTED'].includes(row.listState)" type="success" size="mini" icon="el-icon-document" @click="handleRecord(row)">助力记录</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>

    <moe-dialog :show="showDialog" title="助力记录" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <div class="df1 df fdc">
          <moe-table ref="assistRecordTableRef" url="/shop/assist/recordList" :params="assistRecordParams" :showPage="false" :numberShow="false">
            <el-table-column label="用户信息" min-width="150">
              <template slot-scope="{ row }">
                <div>{{ `用户名称:${row.userName}`}}</div>
                <div>{{ `用户编号:${row.userId}` }}</div>
              </template>
            </el-table-column>
            <el-table-column label="优惠券" min-width="150">
              <template slot-scope="{ row }">
                {{ `满 ${row.condition} 元 减 ${row.discount} 元` }}
              </template>
            </el-table-column>
            <el-table-column label="助力人数" prop="num" min-width="150" />
            <el-table-column label="已助力人数" prop="assistedNum" min-width="150" />
            <el-table-column label="发起时间" min-width="150">
              <template slot-scope="{ row }">
                {{ $moe_time.getTimeStamp(row.createTime) }}
              </template>
            </el-table-column>
            <el-table-column label="活动状态" min-width="150">
              <div :class="$moe_format.getAssistStateRecordColor(row.state)" slot-scope="{ row }">
                {{ $moe_format.getAssistRecordState(row.state) }}
              </div>
            </el-table-column>
          </moe-table>
        </div>
      </div>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageAssistList',
  data() {
    return {
      assistParams: {
        pageNum: 1,
        pageSize: 10,
        listState: '',
        name: '',
        id: '',
        startTime: '',
        endTime: '',
        target: '',
      },
      datetime: [],
      assistRecordParams: {
        id: '',
        pageNum: 1,
      },
      showDialog: false,
    }
  },
  methods: {
    assistSearch(isSearch) {
      if (!isSearch) {
        this.assistParams = {
          pageNum: 1,
          pageSize: 10,
          listState: '',
          name: '',
          id: '',
          startTime: '',
          endTime: '',
          target: '',
        };
        this.datetime = [];
      }

      this.$refs['assistTableRef'].searchData();
    },
    /** 助力拉新活动记录列表 */
    handleRecord({ id }) {
      this.assistRecordParams.id = id;
      this.showDialog = true;
      this.$refs['assistRecordTableRef'].searchData();
    },
    handleDialogClose() {
      this.showDialog = false;
    },
    /** 关闭助力拉新 */
    handleClose({ id, name }) {
      this.$moe_layer.confirm(`您确定要关闭当前选项"${name}"吗？`, () => {
        this.$moe_api.ASSIST_API.closeAssist({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('关闭成功!');
            this.assistSearch(true);
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
    /** 删除助力拉新 */
    handleDelete({ id, name }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.$moe_api.ASSIST_API.delAssist({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功!');
            this.assistSearch(true);
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
  },
}
</script>

<style lang="scss">

</style>